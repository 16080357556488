@if (hasVideoStream) {
  <video autoPlay muted playsInline [srcObject]="participant.videoStream"></video>
}

@if (hasAudioStream) {
  <audio autoPlay playsInline [srcObject]="participant.audioStream"></audio>
}

<div class="participant-info">
  <span class="name">
    {{ participant.userName }}
  </span>
  @if (hasAudioStream) {
    <img src="assets/img/video-conference/mic-on.svg" alt="mic on" />
  } @else {
    <img src="assets/img/video-conference/mic-off.svg" alt="mic off" />
  }
</div>
