import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Participant } from '../../models/video-call-participant';

@Component({
    selector: 'app-video-tile',
    standalone: true,
    imports: [],
    templateUrl: './video-tile.component.html',
    styleUrl: './video-tile.component.scss',
})
export class VideoTileComponent implements OnChanges {
    @Input() participant: Participant;

    hasVideoStream: boolean = false;
    hasAudioStream: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['participant'] && this.participant) {
            this.checkMediaStreams();
        }
    }

    checkMediaStreams(): void {
        this.hasVideoStream = this.participant.videoStream?.getVideoTracks().length > 0;
        this.hasAudioStream = this.participant.audioStream?.getAudioTracks().length > 0;
    }
}
