import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { startLoading } from '../../../shared/store/actions/user-env';
import { VideoCall } from '../../models/video-call';
import { StartVideoCallUseCase } from '../../services/StartVideoCallUseCase';
import { VideoCallGateway } from '../../services/VideoCallGateway';
import { VideoCallsActions } from '../actions/videoCall';

@Injectable()
export class VideoCallEffects {
    loadCurrentVideoCalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startLoading),
            switchMap(() =>
                this.videoCallGateway
                    .getVideoCalls()
                    .pipe(map((videoCalls: VideoCall[]) => VideoCallsActions.videoCallsLoaded({ videoCalls }))),
            ),
        ),
    );

    startVideoCallRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VideoCallsActions.startVideoCallRequested),
            switchMap(({ siteId }) =>
                this.startVideoCallUseCase
                    .exec(siteId)
                    .pipe(map((videoCall: VideoCall) => VideoCallsActions.videoCallStarted({ siteId, videoCall }))),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        @Inject('VideoCallGateway') private videoCallGateway: VideoCallGateway,
        private readonly startVideoCallUseCase: StartVideoCallUseCase,
    ) {}
}
