import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    JoinedParticipantVideoCallEvent,
    LeftParticipantVideoCallEvent,
    TrackStartedVideoCallEvent,
    TrackStoppedVideoCallEvent,
    VIDEO_CALL_EVENT_TYPE,
} from '../models/video-call-event';
import {
    Participant,
    PARTICIPANT_TYPE,
    ParticipantTracks,
    ParticipantType,
    VideoParticipants,
} from '../models/video-call-participant';
import { VideoCallProvider } from './VideoCallProvider';

@Injectable()
export class VideoCallParticipantManager {
    private videoParticipants: BehaviorSubject<VideoParticipants>;

    constructor(@Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider) {
        this.videoParticipants = new BehaviorSubject<VideoParticipants>({
            [PARTICIPANT_TYPE.OWNER]: null,
            [PARTICIPANT_TYPE.GUEST]: null,
        });

        this.initializeEventHandlers();
    }

    getParticipants() {
        return this.videoParticipants.asObservable();
    }

    private eventHandlers = {
        [VIDEO_CALL_EVENT_TYPE.JOINED_PARTICIPANT]: this.addParticipant,
        [VIDEO_CALL_EVENT_TYPE.LEFT_PARTICIPANT]: this.removeParticipant,
        [VIDEO_CALL_EVENT_TYPE.TRACK_STARTED]: this.startTrackForParticipant,
        [VIDEO_CALL_EVENT_TYPE.TRACK_STOPPED]: this.stopTrackForParticipant,
        [VIDEO_CALL_EVENT_TYPE.LEAVE_CALL]: this.resetParticipants,
    };

    private initializeEventHandlers() {
        this.videoCallProvider.getVideoCallEvents().subscribe((event) => {
            if (!(event.type in this.eventHandlers)) {
                console.warn('Unhandled event type:', event);
                return;
            }

            this.eventHandlers[event.type].call(this, event);
        });
    }

    private addParticipant(event: JoinedParticipantVideoCallEvent): void {
        this.updateParticipants(event.participant);
    }

    private startTrackForParticipant(event: TrackStartedVideoCallEvent): void {
        this.updateTrackForParticipant(event.participantTracks, true);
    }

    private stopTrackForParticipant(event: TrackStoppedVideoCallEvent): void {
        this.updateTrackForParticipant(event.participantTracks, false);
    }

    private updateTrackForParticipant(participantTracks: ParticipantTracks, isStarting: boolean): void {
        const participant = this.getParticipant(participantTracks.participantType);
        if (!participant) {
            return;
        }

        const streamKey = participantTracks.kind === 'video' ? 'videoStream' : 'audioStream';
        const stream = participant[streamKey] ?? new MediaStream();

        const track = participantTracks[participantTracks.kind];
        if (track) {
            if (isStarting) {
                stream.addTrack(track);
            } else {
                stream.removeTrack(track);
            }

            this.updateParticipants({
                ...participant,
                [streamKey]: stream,
            });
        }
    }

    private removeParticipant(event: LeftParticipantVideoCallEvent): void {
        const participant = event.participant;
        if (!participant) {
            return;
        }

        participant.videoStream?.getTracks().forEach((track) => track.stop());
        participant.audioStream?.getTracks().forEach((track) => track.stop());

        const updatedParticipants = { ...this.videoParticipants.value };
        updatedParticipants[participant.type] = null;

        this.videoParticipants.next(updatedParticipants);
    }

    private updateParticipants(updatedParticipant: Participant | null): void {
        const participants = { ...this.videoParticipants.value };
        participants[updatedParticipant.type] = updatedParticipant;
        this.videoParticipants.next(participants);
    }

    private resetParticipants(): void {
        const participants = this.videoParticipants.value;

        Object.values(participants).forEach((participant) => {
            if (participant) {
                participant.videoStream?.getTracks().forEach((track) => track.stop());
                participant.audioStream?.getTracks().forEach((track) => track.stop());
            }
        });

        this.videoParticipants.next({
            [PARTICIPANT_TYPE.OWNER]: null,
            [PARTICIPANT_TYPE.GUEST]: null,
        });
    }

    private getParticipant(participantType: ParticipantType) {
        const participants = this.videoParticipants.getValue();
        return participants[participantType];
    }
}
