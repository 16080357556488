import { CompanyId } from '../../alerts/models/Alert';
import { Timestamp } from '../../shared/common.types';
import { UserId } from '../../shared/models/User';
import { SiteId } from '../../site-detail/models/Site';

export type VideoCallStatus = (typeof VIDEO_CALL_STATUS)[keyof typeof VIDEO_CALL_STATUS];

export const VIDEO_CALL_STATUS = {
    AVAILABLE: 'available',
    IN_PROGRESS: 'in_progress',
    INVITATION_SENT: 'invitation_sent',
} as const;

export type VideoCallId = string;

export interface VideoCall {
    id: VideoCallId;
    companyId: CompanyId;
    siteId: SiteId;
    installerId: UserId;
    createdAt: Timestamp;
    url: string;
    ownerToken: string;
    status: VideoCallStatus;
}

export interface ModalConfirmVideoCallInviteData {
    siteId: string;
}
