import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from '../../../shared/store/app-state';
import { VideoCall } from '../../models/video-call';
import { StopVideoCallUseCase } from '../../services/StopVideoCallUseCase';
import { VideoCallsActions } from '../../store/actions/videoCall';
import { VideoCallComponent } from '../video-call/video-call.component';

@Component({
    selector: 'app-video-call-modal',
    templateUrl: './video-call-modal.component.html',
    styleUrls: ['./video-call-modal.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatButton, MatDialogActions, MatDialogClose, TranslateModule, VideoCallComponent],
})
export class VideoCallModalComponent {
    constructor(
        private stopVideoCallUseCase: StopVideoCallUseCase,
        private dialogRef: MatDialogRef<VideoCallModalComponent>,
        private readonly store: Store<AppState>,
        @Inject(MAT_DIALOG_DATA) public data: VideoCall,
    ) {}

    callEnded(): void {
        this.stopVideoCallUseCase.exec(this.data.siteId);
        this.store.dispatch(VideoCallsActions.videoCallStopped({ siteId: this.data.siteId }));
        this.dialogRef.close();
    }
}
