import * as Sentry from '@sentry/angular';
import p from '../package.json';
import { AppConfig } from './app/AppConfig';
import ENV_NAMES from './environments/ENV_NAMES';

function isSentryEnabled(env: ENV_NAMES): boolean {
    const enabledEnvironments = [ENV_NAMES.PROD, ENV_NAMES.PREPROD, ENV_NAMES.TEST];
    return enabledEnvironments.includes(env);
}

export function initSentry(config: AppConfig): void {
    // use 'production' instead of 'prod' to match back-end environment names
    const environment = config.name === ENV_NAMES.PROD ? 'production' : config.name;

    Sentry.init({
        dsn: 'https://a43ed36933284a9094c3ae6dec8df82e@o1161900.ingest.sentry.io/6248659',
        enabled: isSentryEnabled(config.name),
        environment,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracePropagationTargets: [
            config.websiteUrl.replace('https://', ''),
            config.servegoApi.url.replace('https://', ''),
        ],
        release: `${p.name}@${config.gitReleaseVersion}`,
        tracesSampleRate: 0.2,
    });
}
