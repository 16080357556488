import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-video-call-controls',
    standalone: true,
    imports: [],
    templateUrl: './video-call-controls.component.html',
    styleUrl: './video-call-controls.component.scss',
})
export class VideoCallControlsComponent {
    @Output() leaveCallClick: EventEmitter<null> = new EventEmitter();

    leaveCall(): void {
        this.leaveCallClick.emit();
    }
}
