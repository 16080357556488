import { AsyncPipe, KeyValuePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { VideoCall } from '../../models/video-call';
import { VideoParticipants } from '../../models/video-call-participant';
import { VideoCallParticipantManager } from '../../services/VideoCallParticipantManager';
import { VideoCallProvider } from '../../services/VideoCallProvider';
import { VideoCallControlsComponent } from '../video-call-controls/video-call-controls.component';
import { VideoTileComponent } from '../video-tile/video-tile.component';

@Component({
    selector: 'app-video-call',
    standalone: true,
    imports: [VideoTileComponent, AsyncPipe, KeyValuePipe, VideoCallControlsComponent],
    templateUrl: './video-call.component.html',
    styleUrl: './video-call.component.scss',
})
export class VideoCallComponent implements OnInit, OnDestroy {
    @Input() videoCallData: VideoCall;
    @Output() callEnded: EventEmitter<null> = new EventEmitter();
    videoParticipants$: Observable<VideoParticipants>;

    constructor(
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
        private videoCallParticipantManager: VideoCallParticipantManager,
    ) {}

    ngOnInit(): void {
        this.videoParticipants$ = this.videoCallParticipantManager.getParticipants();

        this.videoCallProvider.initializeVideoCall(this.videoCallData.url, this.videoCallData.ownerToken);
    }

    leaveCall(): void {
        this.videoCallProvider.leaveVideoCall();
        this.callEnded.emit();
    }

    ngOnDestroy(): void {
        this.videoCallProvider.destroy();
    }
}
