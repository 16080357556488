import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ToasterJoinVideoCallMessageContent } from '../../../shared/models/Toaster';
import { ModalService } from '../../../shared/services/modal.service';
import { AppState } from '../../../shared/store/app-state';
import { removeToasterMessage } from '../../../site-detail/store/actions/ui';
import { VideoCallModalComponent } from '../video-call-modal/video-call-modal.component';

@Component({
    selector: 'app-video-call-toaster-item',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './video-call-toaster-item.component.html',
    styleUrl: './video-call-toaster-item.component.scss',
})
export class VideoCallToasterItemComponent {
    @Input() content: ToasterJoinVideoCallMessageContent;
    @Input() messageId: string;

    constructor(
        private store: Store<AppState>,
        private modalService: ModalService,
    ) {}

    onClose() {
        this.store.dispatch(removeToasterMessage({ id: this.messageId }));
    }

    openVideoCallModal(): void {
        this.modalService.open(VideoCallModalComponent, {
            disableClose: true,
            panelClass: 'video-call-modal',
            width: '100%',
            maxWidth: '95vw',
            height: '95vh',
            data: {
                siteId: this.content.siteId,
                url: this.content.url,
                ownerToken: this.content.ownerToken,
            },
        });

        this.onClose();
    }
}
